.project {
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 2222222;
}

.project figure {
  position: relative;
  height: 100%;
  width: 100%;
}

.project figure img {
  display: block;
  width: 100%;
  -webkit-transition: all 8s linear;
  -o-transition: all 8s linear;
  transition: all 8s linear;
  height: 100%;
  object-fit: cover;
}

.project figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111;
  opacity: 0.7;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.project.projectLight figure:after {
  opacity: 0.4;
}

.project figcaption {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 3.2em 3.6em;
}

.projectTitle {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  font-family: oswaldRegular, sans-serif;
  font-weight: bold;
  font-size: 1.56em;
  line-height: 1.45;
  letter-spacing: 0.07em;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  text-align: left;
}

.projectCategory {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0 0 -0.8em;
  font-family: oswaldRegular, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.8em;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.projectZoom {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 9em;
  height: 9em;
  margin: -4.5em;
  border-radius: 50%;
  background-color: rgba(197, 164, 126, 0.84);
  overflow: hidden;
  opacity: 0;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.projectZoom:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.625em;
  height: 2.625em;
  background: url(../../../asset/images/zoom.png) 0 0 no-repeat;
  background-size: cover;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.project figure:hover:after {
  opacity: 0;
}

.project figure:hover img {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.project figure:hover .projectTitle {
  top: 30px;
  opacity: 0;
}

.project figure:hover .projectCategory {
  opacity: 0;
  letter-spacing: 2em;
}

.project figure:hover .projectZoom {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 992px) {
  .projectTitle {
    font-size: 1.26em;
  }
}
