.sectionContainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.imageContainer {
  max-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.imageContainer img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100vh;
}

.imageContainer .mainLink {
  width: 100%;
  height: 100%;
  z-index: 9999999;
}

.enterLink {
  font-size: 50px;
  color: #fff;
  position: absolute;
  z-index: 999999999999999;
  left: calc(50% - 110px);
  top: 17%;
  text-decoration: none;
  font-family: montseratSemiBold;
}
.enterLink:hover {
  text-decoration: none;
}

.imageContainer h2 {
  font-size: 50px;
  color: #fff;
  position: absolute;
  z-index: 45;
  left: calc(50% - 360px);
  top: 10%;
  font-family: montseratSemiBold;
}

.mainHeader {
  width: 100vw;
  height: 100vh;
}

.mySwiper {
  width: 100vw;
  height: 100vh;
}
.mySwiper div {
}

@media (max-width: 1100px) {
  .imageContainer h2 {
    width: 100%;
  }
}

@media (max-width: 710px) {
  .enterLink {
    top: 210px;
  }
}

@media (max-width: 550px) {
  .imageContainer h2 {
    font-size: 30px;
    color: #fff;
    left: calc(50% - 210px);
    top: 10%;
  }

  .enterLink {
    font-size: 30px;
    top: 160px;
    left: calc(50% - 50px);
  }
}

@media (max-width: 300px) {
  .imageContainer h2 {
    font-size: 26px;
    color: #fff;
    left: calc(50% - 135px);
    top: 10%;
  }
}
