.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.text {
  font-family: montseratRegular, sans-serif;
  font-weight: 700;
  font-size: 40px;
  width: 100px;
  height: 120px;
  line-height: 1;
  color: #3d3d3d;
}
.text img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rights {
  font-size: 12px;
  color: #aaa;
}
