.projectItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  row-gap: 135px;
  padding: 0 13%;
  margin-bottom: 150px;
}

@media (max-width: 993px) {
  .projectItems {
    padding: 0 3%;
    margin-bottom: 100px;
  }
}

@media (min-width: 994px) and (max-width: 1600px) {
  .projectItems {
    padding: 0 6%;
  }
}

@media (max-width: 410px) {
  .projectItems {
    padding: 0;
  }
}
