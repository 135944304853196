.content {
  display: flex;
  justify-content: space-around;
  column-gap: 90px;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
}

.inputs {
  display: flex;
  column-gap: 12px;
  margin-bottom: 32px;
}
.usernameEmail {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.input select {
  font-size: 1em;
  width: 240px;
  height: 58px;
  border-radius: 0.75em;
  padding-left: 22px;
  color: #666666;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.input textarea,
.input input {
  font-size: 1em;
  width: 240px;
  height: 58px;
  border-radius: 0.75em;
  padding-left: 22px;
  color: #666666;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}
.phoneInputContainer {
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 0.75em;
  padding-left: 15px;
}
.phoneInputContainer input {
  z-index: 222 !important;
}
.phoneInputContainer:focus {
  border-color: var(--main-color);
  background-color: #fff;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.selectorContainer {
  /* border-right: 16px solid transparent; */
}
.selectorContainer input {
  /* border: none; */
}

.input select:focus,
.input textarea:focus,
.input input:focus {
  border-color: var(--main-color);
  background-color: #fff;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input textarea {
  height: 100%;
  resize: none;
}
/* .content a {
  text-decoration: none;
} */

.content a:hover {
  color: #ab8f6f;
}

.contantInfo {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.contantInfo p {
  white-space: nowrap;
}
.fadeRight {
  transform: translate(15%, -50%);
}

.btn {
  font-family: montseratSemiBold;
  position: relative;
  width: 140px;
  height: 50px;
  align-self: flex-end;
  border: 0.2em solid var(--main-color);
  border-radius: 30px;
  padding: 1.1em 2.2em 1.05em;
  font-size: 0.95em;
  color: #fff;
  /* letter-spacing: 0.15em; */
  text-transform: uppercase;
  text-decoration: none;
  background-color: var(--main-color);
  border: 0;
  -webkit-transition: all 0.3s ease-out !important;
  transition: all 0.3s ease-out !important;
  box-shadow: 0 15px 34px rgba(197, 164, 126, 0.5);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.btn:hover {
  background-color: #cc9f6b;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
}
.btn img {
  width: 13px;
  height: 10px;
  margin-left: 8px;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swings {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

.contactTitle {
  font-size: 3em;
}

@media (max-width: 992px) {
  .container {
  }

  .fadeRight {
    transform: translate(-52%, -119%);
    font-size: 8em;
  }
  .contactTitle {
    font-size: 2.1875em;
  }
  .content {
    flex-direction: column;
  }
  .contantInfo {
    margin-bottom: 3em;
  }
  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 12px;
  }
  .input {
    width: 100%;
  }
  .input select,
  .input input {
    width: 100%;
  }
}
