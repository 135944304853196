.objects {
  position: relative;
}

.objectLabel {
  position: absolute;
  width: 0.8em;
  height: 0.8em;
  background-color: #d32b2b;
  border-radius: 50%;
  cursor: pointer;
}

.popover.top {
  margin-top: -20px;
}

.objectInfo {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 45px;
  width: 12.4em;
  margin-left: -6.2em;
  padding: 1.1em 1.3em 3em;
  border: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s, visibility 0.3s;
}

.objectInfo:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-top: 14px solid rgba(0, 0, 0, 0.6);
}

.objectTitle {
  font-size: 1em;
  font-family: montseratSemiBold;
  font-weight: normal;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: var(--main-color);
  padding: 0 0 1em;
  border-bottom: 1px solid rgba(249, 249, 249, 0.2);
  margin: 0 0 0.5em;
}

.objectContent {
  font-family: montseratLight, sans-serif;
  font-size: 0.75em;
  line-height: 2.6;
  color: #fff;
  padding: 0;
}

.popover.top > .arrow {
  border-top-color: transparent;
}

.popover.top > .arrow:after {
  border-top-color: rgba(0, 0, 0, 0.6);
}

.objectLabel.active .objectInfo {
  /* display: block; */
  opacity: 1;
  visibility: visible;
}

.containerPadding {
  padding: 0 12%;
}

@media (min-width: 1650px) and (max-width: 1800px) {
  .containers {
    padding: 0px 11%;
  }
}

@media (min-width: 993px) and (max-width: 1300px) {
  .containerPadding {
    padding: 0px 7%;
  }
}

@media (min-width: 1301px) and (max-width: 1650px) {
  .containerPadding {
    padding: 0px 9%;
  }
}

@media (max-width: 992px) {
  .containerPadding {
    padding: 0 !important;
  }

  .objectLabel {
    width: 0.8em;
    height: 0.8em;
  }

  .objects {
    margin-bottom: 4em;
  }
}
