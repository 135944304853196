.fadeLeft,
.fade-title-right {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 10.8em;
  line-height: 1;
  color: #000;
  position: absolute;
  left: 0;
  top: 0.03em;
  opacity: 0.035;
  -webkit-transform: translate(-142%, 50%);
  -ms-transform: translate(-142%, 50%);
  -o-transform: translate(-142%, 50%);
  transform: translate(-142%, 50%);
}

.fade-title-right {
  left: 100%;
}

.specs {
  position: relative;
}

.lines {
  background-color: rgb(56, 56, 56);
  position: absolute;
  width: 1px;
  height: 228%;
  top: -65%;
  opacity: 0.1;
  left: 0%;
}

.linesRight {
  background-color: rgb(56, 56, 56);
  position: absolute;
  width: 1px;
  height: 228%;
  top: -65%;
  opacity: 0.1;
  right: 0%;
}

.sectionContent {
  margin-top: 110px;
  display: grid;
  grid-template-columns: 1.05fr 1fr 1fr;

  grid-template-rows: 370px;
}
.aboutTitle {
  margin-bottom: 30px;
  font-size: 2.275em;
}
.service {
  padding: 0 5em;
}

.aboutInfoText {
  line-height: 1.65;
  color: #747474;
  font-size: 16px;
  padding-right: 10px;
}

.serviceList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: calc(100% - 120px);
  justify-content: space-between;
}
.serviceItem {
  display: flex;
  flex-direction: column;
}

.serviceItem h4 {
  margin-top: 14.5px;
}

.aboutImg img {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24%;
}
