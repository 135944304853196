.entry {
  margin: 3.2em 0;
}

.entryTitle {
  font-size: 4.2em;
  margin: 0 0 1em;
  text-align: center;
}

.entryText {
  font-size: 1.8em;
  line-height: 1.6;
}

@media (max-width: 992px) {
  .entry {
    padding: 0 3.1em;
  }
  .entryTitle {
    font-size: 3.2em;
    text-align: left;
  }
}

@media (max-width: 470px) {
  .entry {
    padding: 0;
  }
}
