.contactDetails {
  width: 100%;
  display: grid;
  height: 600px;
  grid-template-columns: 1fr 1fr;
}

.googleMap {
  width: 100%;
  height: 100%;
}

.contactInfo {
  color: #fff;
  padding: 0;
  width: 100%;
  height: 100%;
}

.contanctInfoContent {
  padding: 5.5em 5.6em;
  background-color: var(--main-color);
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
}

.contactInfoTitle {
  font-family: oswaldRegular, sans-serif;
  font-size: 80px;
  letter-spacing: 0.32em;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.1;
  position: absolute;
  top: 100%;
  left: 100%;
  margin-top: -0.5em;
  margin-left: -2.3em;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.contactRow {
  display: flex;
}

.contactRow:last-child {
  margin-bottom: 0;
}

.contactRow h4 {
  margin: 0 0 0.8em;
  color: #fff;
  font-size: 28px;
}

.contactRow .fa {
  width: 1.1em;
  padding: 1px;
  text-align: center;
  font-size: 1.3em;
  line-height: 1.25;
  padding-top: 10px;
}

.contactBody {
  padding-left: 1em;
}

.contactContent {
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.contactContent span {
  font-size: 1.1em;
}
.contactContent a {
  color: #fff;
  font-size: 1.1em;
}

.phoneRow {
  font-size: 1.5em;
  letter-spacing: 0.2em;
  margin-top: 12px;
}

.phoneRow a {
  color: #fff;
  font-size: 1.1em;
}

@media (max-width: 992px) {
  .contactDetails {
    height: auto;
    grid-template-columns: 1fr;
  }

  .googleMap {
    height: 420px;
  }

  .contanctInfoContent {
    padding: 3.6em 3.6em;
    flex-direction: column;
    row-gap: 22px;
  }
}

@media (max-width: 460px) {
  .contanctInfoContent {
    padding: 5.5em 1.6em;
  }
}

@media (max-width: 360px) {
  .contanctInfoContent {
    padding: 5.5em 0.6em;
  }
}
