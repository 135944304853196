.projectDetails {
  margin-top: 7.1em;
  margin-bottom: 40px;
}

.container {
  padding: 0 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projectDetailsItem {
  margin-bottom: 7.1em;
  width: 100%;
}

.projectDetailsInfo {
  position: absolute;
  z-index: 12;
  left: 0;
  top: 13%;
  bottom: 13%;
  width: 40%;
  height: max-content;
}

.projectDetailsItem:nth-child(odd) .projectDetailsImg {
  padding-left: 15px;
  padding-right: 15px;
}

.projectDetailsItem:nth-child(even) .projectDetailsImg {
  padding-right: 15px;
  padding-left: 15px;
}

.projectDetailsItem {
  position: relative;
}

.projectDetailsImg {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}
.projectDetails img {
  max-height: 750px;
}

.projectDetailsInfo {
  background-color: var(--main-color);
  padding: 4.8em 3.1em 4.8em;
  color: #fff;
  padding-bottom: 12em;
}

.projectDetailsTitle {
  position: relative;
  font-family: oswaldRegular, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.5;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 0.7em;
}

.projectDetailsTitle:before {
  content: "";
  position: absolute;
  bottom: -0.35em;
  width: 10em;
  border-top: 1px solid #ededed;
  left: -4.4em;
  width: 5.5em;
}

.projectDetailsDesc {
  font-size: 18px;
  line-height: 1.72;
}

.projectDetailsDesc p:last-child {
  margin-bottom: 0;
}

.projectDetailsItem:nth-child(even) .projectDetailsInfo {
  right: 0;
  left: auto;
  top: 13%;
  background-color: #212121;
}

.projectDetailsImgSecond {
  justify-content: flex-end;
}

.projectDetailsInfoSecond h3 {
  font-size: 18px;
  line-height: 1.72;
}

.projectLoaders {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1650px) {
  .projectDetailsInfo {
    padding: 2.8em 2.1em 2.8em;
    height: min-content;
    width: 50%;
  }
}

@media (max-width: 992px) {
  .mobailResponsive {
    display: flex;
    flex-direction: column;
  }

  .projectDetailsInfo {
    position: relative;
    width: 100%;
  }

  .projectDetailsItem .projectDetailsImg {
    padding: 0 !important;
  }

  .container {
    padding: 0;
  }
  .projectDetailsItem {
    margin: 0;
  }
  .mobailResponsiveSecond {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .projectDetails {
    margin-bottom: 70px;
    margin-top: 0;
  }

  .projectDetailsImg {
  }
  .projectDetails img {
    max-height: 500px;
    width: 100%;
    object-fit: cover;
  }
}
