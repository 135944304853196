.popupContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 344px;
  height: 68px;
  padding: 2px;
  z-index: 2222222;
  transition: bottom 0.6s ease-in-out, top 0.6s ease-in-out;
}
.openedContainer {
  bottom: 354px;
}

.popupContainer button {
  background: transparent;
  border: none;
  box-shadow: none;
}

@keyframes slide-in {
  from {
    transform: translateY(200px);
  }
  to {
    transform: translateY(0);
  }
}
.popupTrigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding-left: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 10px 10px 0px;
  background-color: var(--main-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  cursor: pointer;
  padding-left: 14px;
  animation: slide-in 0.6s ease-in-out;
}
.opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.popupTrigger:hover {
  background-color: var(--main-color);
}

.popupTrigger:hover img {
  transform: scale(1.1);
}
.popupTrigger img {
  height: 48px;
  width: 48px;
  transition: transform 0.4s ease-in-out;
}

.popupInfo {
  padding: 6px;
  flex-grow: 1;
}
.popupInfo h3 {
  color: #ffffff;
  font-style: normal;
  font-size: 15px;
  font-family: Helvetica, Arial !important;
  line-height: 1.2;
  margin-bottom: 6px;
}
.popupInfo p {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  color: white;
  line-height: 1;
  font-family: Helvetica, Arial !important;
}

.sectionPopup {
  position: relative;
}

.popupRoot {
  position: absolute;
  bottom: 60px;
  right: 0;
  z-index: 2222;
  width: 100%;
  height: 330px;
  display: none;
}

.openedPopRoot {
  bottom: 0;
  top: 63px;
  display: flex;
}

.popupContent {
  padding: 10px 25px;
  background-color: white;
  z-index: 1000;
  position: relative;
}

/* form  */

.inputSelectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
}

.input input {
  font-size: 1em;
  width: 100%;
  height: 58px;
  border-radius: 0.75em;
  padding-left: 22px;
  color: #666666;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  margin-bottom: 20px;
}

.input select {
  font-size: 1em;
  width: 100%;
  height: 58px;
  border-radius: 0.75em;
  padding-left: 22px;
  color: #666666;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}

.input select:focus,
.input textarea:focus,
.input input:focus {
  border-color: var(--main-color);
  background-color: #fff;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.popupContainer .btn {
  font-family: montseratSemiBold;
  position: relative;
  width: 100%;
  height: 45px;
  align-self: flex-end;
  border: 0.2em solid var(--main-color);
  border-radius: 30px;
  padding: 1.1em 2.2em 1.05em;
  font-size: 0.95em;
  color: #fff;
  /* letter-spacing: 0.15em; */
  text-transform: uppercase;
  text-decoration: none;
  background-color: var(--main-color);
  border: 0;
  -webkit-transition: all 0.3s ease-out !important;
  transition: all 0.3s ease-out !important;
  box-shadow: 0 15px 34px rgba(197, 164, 126, 0.5);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  background-color: #cc9f6b;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
}

.popUpFb {
  color: #fff;
  text-decoration: none;
}

.popUpFb:hover {
  color: #fff;
  text-decoration: none;
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -14px;
  right: 4px;
  cursor: pointer;
  animation: slide-in 0.6s ease-in-out;
}

@media (max-width: 460px) {
  .popupContainer {
    width: 253px;
  }
}
