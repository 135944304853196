.projectList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.projectContainer {
  height: 60vh;
}

.moreProjects {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 110px;
}

.moreProjects button {
  background-color: #f2f2f2;
  color: #a7a7a7;
  font-family: montseratSemiBold;
  border: 0.2em solid var(--main-color);
  border-radius: 30px;
  padding: 1.1em 2.2em 1.05em;
  font-size: 0.875em;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-decoration: none;
  border: 0;
  -webkit-transition: all 0.3s ease-out !important;
  transition: all 0.3s ease-out !important;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 1.42857143;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.moreProjects button:hover {
  color: #fff;
  background-color: #cc9f6b;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out both;
}

@media (max-width: 460px) {
  .projectList {
    display: grid;
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 992px) {
  .projectList {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 993px) and (max-width: 1600px) {
  .projectList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
