.headerContainer {
  display: flex;
  padding: 1%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.brand {
  display: inline-block;
  font-family: montseratRegular, sans-serif;
  font-weight: 700;
  font-size: 2.5vmin;
  line-height: 1;
  z-index: 11111111;
}

.brand img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.brand:hover,
.brand:focus {
  color: #c5c4c2;
  text-decoration: none;
}

.headerPhone {
  font-family: oswaldRegular, sans-serif;
  font-weight: 300;
  font-size: 0.75em;
  white-space: nowrap;
  letter-spacing: 1px;
  color: #fff;
}

.headerPhone a {
  color: var(--main-color);
}

.headerPhone a:hover {
  color: #c5c4c2;
}

.socialList {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.socialList a {
  font-size: 1.6vmin;
}

.socialList .fa {
  font-size: 1.6vmin;
  color: var(--main-color);
}

.socialList a:hover {
  color: #c5c4c2;
  text-decoration: none;
}

.mobailMenuCotnainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

@media (max-width: 1000px) {
  .headerContainer {
    display: flex;
    padding: 1.5%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: transparent;
    position: absolute;
    z-index: 11111;
    row-gap: 15px;
  }

  .headerPhone a {
    color: #e7e7e7;
  }
  .headerPhone:hover {
    color: var(--main-color);
  }
  .mobailMenuCotnainer {
    z-index: 999999999;
  }

  .socialList a {
    color: #e7e7e7;
    font-size: 16px;
  }
  .socialList a:hover {
    color: var(--main-color);
  }

  .brand {
    z-index: 999999999999;
    color: #e7e7e7;
    font-size: 20px;
  }
  .brand:hover {
    color: var(--main-color);
  }
}
