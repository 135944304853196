.navDesktop {
  z-index: 2;
  width: 100%;
}

.brand {
  display: none;
  font-family: montseratRegular;
  position: relative;
  top: -0.22em;
  font-size: 1.6em;
}
.brand img {
  width: 50px;
  height: 30px;
  object-fit: contain;
}

.navbarDescMenu {
  font-family: oswaldRegular, sans-serif;
  font-weight: 300;
  font-size: 1.1em;
  letter-spacing: 0.32em;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbarDescMenu li {
  position: relative;
  -webkit-perspective: 2000px;
  perspective: 2000px;
}

.navbarDescMenu li a {
  display: block;
  text-decoration: none;
  color: #000;
}

.navbarDescMenu li a:hover,
.navbarDescMenu .active > a {
  color: var(--main-color);
}

.navbarDescMenu > li {
  margin-left: 5.1em;
}

.navbarDescMenu > li > a {
  background-color: transparent;
  letter-spacing: 2px;
  font-weight: bold;
}

.navbarDescMenu li ul {
  position: absolute;
  z-index: 10;
  left: 100%;
  top: 60%;
  visibility: hidden;
  min-width: 200px;
  opacity: 0;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.95);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbarDescMenu > li > ul {
  left: -20px;
  margin-top: 0;
}

.navbarDescMenu li:hover > ul {
  top: 0;
  visibility: visible;
  opacity: 1;
}

.navbarDescMenu > li:hover > ul {
  top: 100%;
}

.navbarDescMenu li li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.navbarDescMenu li li:last-child {
  border-bottom: 0;
}

.navbarDescMenu li li a {
  color: #000;
  padding: 12px 20px 10px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.95) !important;
  padding: 1.4em 0 20px 0;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  padding: 1%;
}

.fixed .navbarDescMenu a {
  color: #000 !important;
  margin-bottom: 0;
}
.fixed .navbarDescMenu .active {
  color: var(--main-color) !important;
  font-weight: bold;
}
.active {
  color: var(--main-color) !important;
  font-weight: bold;
}

.fixed .navbarDescMenu a:active,
.fixed .navbarDescMenu a:hover {
  color: var(--main-color) !important;
}

.fixed .brand {
  display: inline-block !important;
  color: #3d3d3d;
  font-weight: 700;
}

.fixed .container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

/* mobile */

.navMobile {
  display: none;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  margin-right: 15px;
  padding: 16px 0;
  padding-top: 0;
}
.mobileNavbarMenu {
  font-family: oswaldRegular, sans-serif;
  font-weight: 300;
  font-size: 1.1em;
  letter-spacing: 0.32em;
  text-transform: uppercase;
  padding-top: 1em;
  margin-bottom: 0;
  animation-name: slideInDown;
  animation-duration: 0.4s;
  animation-timing-function: ease-in;
}

.brandMobile {
  display: block;
  width: 130px;
  height: 100px;
}
.brandMobile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.collapse {
  display: none;
}
.navBtnMenu {
  margin-top: 25px;
}
.mobileNavbarMenu li a {
  padding: 16px;
  display: block;
  color: #000;
  text-decoration: none;
}
.mobileNavbarMenu li {
  border-top: 1px solid #ececec;
}
.activeMobile {
  background-color: var(--main-color) !important;
  color: #fff !important;
}
.navBarCollapse {
  display: block !important;
}
.inMenuButton {
  margin-top: 30px;
}
.inMenuButton span {
  background-color: var(--main-color) !important;
  width: 35px !important;
  height: 3px !important;
}
.fixedMobail {
  background-color: #fff !important;
}

@media (max-width: 1000px) {
  .navDesktop {
    display: none;
  }
}

@media (max-width: 999px) {
  .navMobile {
    display: block !important;
  }
}
