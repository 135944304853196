.mainHeader {
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

.mainHeader img {
  width: 100vw;
  height: 100vh;
  /* object-fit: fill; */
  object-fit: cover;
  object-position: bottom;
}

.scrollContainer {
  display: flex;
  overflow-x: hidden;
  /* Additional styling like width, height, margins, etc. */
}

.slide {
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgrImg {
  /* overflow: hidden; */
  position: relative;
}

.slideContent {
  position: absolute;
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 40%;
  z-index: 11111111;
}

.slideContent h2 {
  font-family: oswaldRegular, sans-serif;
  font-size: 55px;
  letter-spacing: 0.32em;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 16px;
  font-weight: 400;
  text-align: right;
  margin-bottom: 34px;
  z-index: 11111111;
}

.slideContent p {
  font-family: montseratLight;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 22px;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 40px;
  padding-right: 13px;
  color: #fff;
  z-index: 11111111;
}

/* .slideContent a {
  font-family: montseratSemiBold;
   position: absolute; 
  display: inline-block;
  border: 0.2em solid var(--main-color);
  border-radius: 30px;
  padding: 1.1em 2.2em 1.05em;
  font-size: 0.875em;
  color: #fff;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-decoration: none;
  background-color: rgb(197, 164, 126);
  box-shadow: 0px 15px 34px 0px rgba(197, 164, 126, 0.2);
  border: 0;
  z-index: 999999999999;
  cursor: pointer;
} */
.sliderContainer {
  position: relative;
}

.learnMore:hover {
  background-color: #aa8c69;
  cursor: pointer;
}

.mainHeaderLoader {
  top: 40%;
  z-index: 1111111;
  left: 50%;
  position: absolute;
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .slideContent {
    left: 55%;
    top: 50%;
  }

  .slideContent h2 {
    z-index: 11111;
  }
}

@media (min-width: 992px) and (max-width: 1700px) {
  .mainHeader img {
    object-fit: cover;
  }
}

@media (max-width: 992px) {
  .mainHeader img {
    object-fit: cover;
  }

  .slideContent h2 {
    font-size: 35px;
  }

  .slideContent {
    left: 55%;
  }
}

@media (max-width: 600px) {
  .slideContent h2 {
    font-size: 25px;
  }
  .slideContent {
    left: 65%;
  }
}
