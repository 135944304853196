.imageContainer {
  height: 900px;
  width: 50%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.imageContainer .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.imageContainer .swiper-slide {
  background-size: cover;
  background-position: center;
}

.imageContainer .mySwiper2 {
  height: 80%;
  width: 100%;
}

.imageContainer .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.imageContainer .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.imageContainer .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.imageContainer .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thumbnailImages {
  cursor: pointer;
}
.magnifier {
  /* Styles for <div> around image and magnifying glass */
}

.magnifier-image {
  /* Styles for large image */
}

.magnifying-glass {
  /* Styles for magnifying glass */
}

@media (max-width: 1450px) {
  .imageContainer {
    height: 650px;
  }
}

@media (max-width: 993px) {
  .imageContainer {
    height: 780px;
    width: 100%;
  }
}
