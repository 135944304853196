.card {
  max-width: 400px;
  box-shadow: 0px 14px 20px rgb(0 0 0 / 23%);
  width: 90%;
  margin-inline: auto;
}

.cardContent {
  text-align: center;
  background: #fff;
  padding: 25px 30px;
  box-shadow: 0px 14px 20px rgb(0 0 0 / 23%);
}
.card h2 {
  margin-bottom: 5px;
  font-size: 1.25rem;
  font-family: oswaldRegular, serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  font-family: oswaldRegular, serif;
  font-weight: 300;
  margin-top: 4px;
}

.swiper {
  max-width: 320px;
  height: 400px;
}

.swiperSlide {
  position: relative;
  transition: all 0.4s ease;
  visibility: visible !important;
}

.swiperSlide:hover {
  transform: scale(1.1);
}

.swiperSlide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.projectLoaders {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
