.itemInfo {
  background-color: rgb(181, 140, 95);
  width: 100%;
  height: auto;
  padding-top: 10%;
  flex-direction: column;
  display: flex;
  row-gap: 30px;
  color: #fff;
  padding-left: 6%;
}

.itemInfo p {
  font-size: 35px;
}

@media (max-width: 993px) {
  .itemInfo {
    width: 100%;
    height: max-content;
    padding: 5% 0;
    flex-direction: column;
    row-gap: 30px;
    padding-left: 6%;
  }
  .itemInfo p {
    font-size: 28px;
  }
}
