.pagesInfo {
  position: relative;
  overflow: hidden;
  min-height: 420px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 0;
  display: flex;
  min-height: 0;
  padding: 25.7vmin 0 17vmin;
  justify-content: flex-end;
}
.pagesInfo img {
  position: absolute;
  left: 0;
  width: 100%;
  object-fit: cover;
  top: 0;
  height: 100%;
}

.pagesInfo:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #171717;
  opacity: 0.6;
}
.pagesInfoTitle {
  z-index: 25;
  width: 75%;
}

.pagesInfoTitle h1 {
  width: 100%;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  font-size: 10vmin;
  font-weight: bold;
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .slideContent {
    left: 55%;
    top: 50%;
  }

  .slideContent h2 {
    z-index: 111111;
  }
}

@media (min-width: 992px) and (max-width: 1700px) {
  .pagesInfoTitle h1 {
    font-size: 6vmin;
  }

  .pagesInfo {
    /* padding: 25.7vmin 0 17vmin; */
    padding-top: 30vmin;
  }
  .pagesInfoTitle {
    padding-top: 5%;
  }
}

@media (max-width: 992px) {
  .pagesInfoTitle h1 {
    font-size: 35px;
    padding-right: 5%;
  }
  .pagesInfoTitle {
    padding-left: 15%;
  }
  .pagesInfoTitle h1 {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .pagesInfoTitle h1 {
    font-size: 25px;
  }
}
