.itemInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 993px) {
  .itemInfoContainer {
    flex-direction: column;
  }
}
