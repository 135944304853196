.brandPanel {
  position: absolute;
  z-index: 9999999999;
  left: 0;
  top: 0;
  width: 39.47vmin;
  padding: 4.8vmin 2em 4.8vmin;
  background-color: transparent;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.brand {
  display: inline-block;
  font-family: montseratRegular, sans-serif;
  font-weight: 700;
  font-size: 4vmin;
  line-height: 1;
  color: #e7e7e7;
}

.brand:hover,
.brand:focus {
  color: #fff;
  text-decoration: none;
}

.brandName {
  position: absolute;
  z-index: -1;
  bottom: -0.4em;
  right: -0.37em;
  font-family: montseratRegular, sans-serif;
  font-weight: 700;
  font-size: 8.75em;
  line-height: 1;
  opacity: 0.05;
}

.headerPhone {
  font-family: oswaldRegular, sans-serif;
  font-weight: 300;
  font-size: 0.81em;
  letter-spacing: 0.32em;
  position: absolute;
  z-index: 15;
  color: #fff;
  right: 1vmin;
  top: 5.9vmin;
}

@media (max-width: 480px) {
  .headerPhone {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .headerPhone {
    display: none;
  }
}

.verticalPanelContent {
  position: absolute;
  width: 19.735vmin;
  bottom: auto;
  top: 12.2vmin;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.verticalPanelContent {
  z-index: 9999999999999999;
}
.headerPhone a {
  color: #fff;
}

.verticalPanelInfo {
  margin-bottom: 3.5vmin;
}

.verticalPanelInfo .line {
  height: 5vmin;
  width: 1px;
  margin: 15px auto 0;
  background-color: #c5c4c2;
}

.verticalPanelTitle {
  font-family: montseratSemiBold;
  font-size: 10px;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1em;
  color: #3d3d3d;
  position: relative;
  left: -6px;
  margin: 0 auto;
  width: 1px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

@media (max-height: 850px) {
  .verticalPanelTitle {
    display: none;
  }
}

.socialList {
  margin-bottom: 3.5vmin;
}

.socialList li {
  margin-top: 3.8vmin;
}

.socialList a {
  font-size: 2.4vmin;
  color: #c5c4c2;
}

.socialList .fa {
  font-size: 2.4vmin;
  color: #c5c4c2;
}

.socialList a:hover {
  color: var(--main-color);
  text-decoration: none;
}

@media (max-width: 1845px) {
  .brandPanel {
    height: 33.7vmin;
    width: 35.47vmin;
  }
}

@media (max-width: 1400px) {
  .headerPhone {
    display: none;
  }
}

@media (max-width: 1100px) {
  .headerPhone {
    z-index: 99999999999;
  }

  .verticalPanelContent {
    z-index: 9999999999999999;
  }
}

@media (max-width: 992px) {
  .headerPhone {
    top: 50px;
    display: block;
    left: 40vmin;
  }
}

@media (max-width: 600px) {
  .headerPhone {
    top: 4.8vmin;
    left: 38vmin;
    font-size: 8px;
  }
}
