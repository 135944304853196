.sectionContent {
  margin-top: 100px;
}

.row-services {
  margin-left: -4.1em;
  margin-right: -4.1em;
}

.colService {
  padding: 0 4.1em;
}

.serviceITem {
  margin-bottom: 2em;
}

.serviceITem:last-child {
  margin-bottom: 0;
}

.serviceITem h4 {
  margin-top: 0.8em;
  margin-bottom: 1.1em;
  font-size: 18px;
}

.services .serviceITem h4 {
  margin-top: 1.25em;
}

.serviceITem p:last-child {
  margin-bottom: 0;
}

.serviceITem p {
  font-family: montseratLight, sans-serif;
  font-size: 16px;
  line-height: 1.65;
  color: #747474;
}

.servicesLayout {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 992px) {
  .colService {
    padding: 0 3.1em;
  }
}

@media (max-width: 470px) {
  .colService {
    padding: 0;
  }
}
