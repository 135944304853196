@import url("./css/bootstrap.min.css");
@import url("./css/font-awesome.min.css");
@import url("./css/animations.css");

:root {
  --main-color: #c5a47e;
  /* --main-color: #6ba781; */
  --text-color: #3d3d3d;
  --bonus-color: #6ba781;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 75%;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: montseratLight, sans-serif;
  /* font-size: 1em; */
  line-height: 1.65;
  color: #747474;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: var(--main-color);
}

::selection {
  background-color: var(--main-color);
  color: #fff;
}

-webkit-::selection {
  background-color: var(--main-color);
  color: #fff;
}

::-moz-selection {
  background-color: var(--main-color);
  color: #fff;
}

h1,
.h1 {
  font-family: oswaldRegular, sans-serif;
  font-weight: 400;
  font-size: 8vmin;
  line-height: 1.2;
  letter-spacing: 0.32em;
  text-transform: uppercase;
  color: #3d3d3d;
}

h2,
.h2 {
  font-family: oswaldRegular, sans-serif;
  font-weight: 400;
  font-size: 2.1875em;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: #3d3d3d;
}

h3,
.h3 {
  font-family: montseratSemiBold;
  font-size: 1.875em;
  line-height: 1.1;
  font-weight: normal;
  color: #3d3d3d;
}

h4,
.h4 {
  font-family: montseratSemiBold;
  font-size: 1.125em;
  line-height: 1.35;
  font-weight: normal;
  color: #3d3d3d;
  letter-spacing: 0.11em;
  text-transform: uppercase;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a {
  color: inherit;
}

h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h4 a:hover {
  text-decoration: none;
}

p {
  margin-bottom: 1.6em;
}

b,
strong {
  font-family: montseratRegular, sans-serif;
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  color: var(--main-color);
  -webkit-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

a:hover,
a:focus {
  color: var(--main-color);
  outline: none;
}

a:focus {
  text-decoration: none;
}

.slick-arrow::before {
  font-size: 35px !important;
}

.slick-active {
  z-index: 20;
}

.section-header {
  position: relative;
}

.section-title {
  text-align: center;
  margin: 0;
  margin-left: 0.22em;
}

.layout {
  position: relative;
  overflow: hidden;
}

.text-primary {
  color: var(--main-color);
}

.page-lines {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.page-lines .col-line {
  padding: 0;
}

.page-lines .line {
  position: absolute;
  left: 0;
  width: 1px;
  height: 50000px;
  background-color: #f4f4f4;
}

.page-lines .col-line:last-child .line:last-child {
  left: auto;
  right: 0;
}

.row-base {
  margin-top: -3em;
}

.col-base {
  margin-top: 3em;
}

.section {
  position: relative;
  margin-top: 7.1em;
  margin-bottom: 7.1em;
}

.section-header {
  position: relative;
}

.section-title {
  text-align: center;
  margin: 0;
  margin-left: 0.32em;
}

.fade-title-left,
.fade-title-right {
  font-family: montseratRegular, sans-serif;
  font-weight: 700;
  font-size: 10.8em;
  line-height: 1;
  color: #000;
  position: absolute;
  left: 0;
  top: 0.03em;
  opacity: 0.035;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.fade-title-right {
  left: 100%;
}

.section-content {
  margin-top: 7.1em;
}

@font-face {
  font-family: montseratBlack;
  src: url(./asset/fonts/Montserrat-Black.otf);
}

@font-face {
  font-family: montseratBold;
  src: url(./asset/fonts/Montserrat-Bold.otf);
}

@font-face {
  font-family: montseratExtraBold;
  src: url(./asset/fonts/Montserrat-ExtraBold.otf);
}

@font-face {
  font-family: montseratLight;
  src: url(./asset/fonts/Montserrat-Light.otf);
}

@font-face {
  font-family: montseratRegular;
  src: url(./asset/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: montseratSemiBold;
  src: url(./asset/fonts/Montserrat-SemiBold.otf);
}

@font-face {
  font-family: montseratUltraLight;
  src: url(./asset/fonts/Montserrat-UltraLight.otf);
}

@font-face {
  font-family: oswaldBold;
  src: url(./asset/fonts/Oswald-Bold.ttf);
}

@font-face {
  font-family: oswaldRegular;
  src: url(./asset/fonts/Oswald-Regular.ttf);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.footer {
  padding: 50px 0;
  padding-bottom: 30px;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

.mySwiper .swiper-button-next::after,
.mySwiper .swiper-button-prev::after {
  width: 50px;
  height: 50px;
  font-size: 30px; /* Adjust the font size if necessary */
}

.icon-bar {
  background-color: #fff;
}

.containers {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 22%;
}

.mainSwiperClass {
  height: 100vh;
}

@media (max-width: 992px) {
  .containers {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0px 9%;
  }
}

@media (max-width: 700px) {
  .containers {
    padding: 0 15px;
  }
}

@media (min-width: 1650px) and (max-width: 1800px) {
  .containers {
    padding: 0px 15%;
  }
}

@media (min-width: 993px) and (max-width: 1300px) {
  .containers {
    padding: 0px 10%;
  }
}

@media (min-width: 1301px) and (max-width: 1650px) {
  .containers {
    padding: 0px 12%;
  }
}
